import React from "react";
import styled from "styled-components";

import { LightYellowWrapper } from "../components/parts/LightYellowWrapper";
import { Container } from "../components/parts/Container";
import { Button } from "../components/parts/Button";
import { SpBr } from "../components/parts/SpBr";
import { FontSize, Color } from "../utils/Const";

const FreeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  margin: 2rem auto;
`

const Title = styled.h2`
  width: 100%;
  color: ${Color.Orange};
  font-size: ${FontSize.md};
  text-align: center;
`

export const Free = () => {
  return(
    <LightYellowWrapper>
      <Container>
        <FreeWrapper>
          <Title>登録不要で、<SpBr />今すぐ無料体験が<SpBr />できます。</Title>
          <Button href={`https://demo.otsutome.net/demo/login`}>無料で体験する</Button>
        </FreeWrapper>
      </Container>
    </LightYellowWrapper>
  )
}