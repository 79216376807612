import React from "react";
import styled from "styled-components";

import img1 from  "../images/recommend-document.png";
import img2 from  "../images/recommend-out.png";
import img3 from  "../images/recommend-cost.png";
import img4 from  "../images/recommend-sp.png";
import img5 from  "../images/recommend-remote-work.png";
import img6 from  "../images/recommend-system.png";

import { Container } from "../components/parts/Container";
import { SpBr } from "../components/parts/SpBr";
import { Responsive, Color, FontSize } from "../utils/Const";

const Wrapper = styled.div`
  width: 100%;
  padding: 2rem 0;
  box-sizing: border-box;
  background-color: ${Color.White};
  position: relative;
`;

const FunctionsWrapper = styled.div`
  width: 100%;
`

const Functions = styled.div`
  display: flex;
  @media(max-width: ${Responsive.SP}px) {
    flex-direction: column;
  }
`

const Function = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 4rem 2rem;
  padding: 0;
  box-sizing: border-box;
  @media(max-width: ${Responsive.SP}px) {
    margin: 2rem 0;
  }
`

const CatchCopyTitle = styled.h2`
  color: ${Color.Orange};
  font-size: ${FontSize.md};
  text-align: center;
`

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const TitleImg = styled.h3`
  position: relative;
  padding-left: 50px;
  font-size: ${FontSize.sm};
  &::before {
    content: attr(data-num);
    position: absolute;
    top: 25%;
    left: 0;
    transform: translateY(-50%);
    color: ${Color.Orange};
    opacity: .2;
    font-size: ${FontSize.xl};
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 1px;
    background-color: ${Color.Orange};
    opacity: .2;
  }
`

const Img = styled.div`
  width: 240px;
  height: 240px;
  background-image: url(${({theme}) => theme.img});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: 15px 15px 0 ${Color.Shadow};
  @media(min-width: ${Responsive.SP}px) and (max-width: ${Responsive.PC}px) {
    width: 200px;
    height: 200px;
  }  
  @media(max-width: ${Responsive.SP}px) {
    width: 200px;
    height: 200px;
  }
`

export const Recommend = () => {
  return(
    <Wrapper>
      <Container>
        <FunctionsWrapper>
          <CatchCopyTitle>こんな方に<SpBr />オススメです！</CatchCopyTitle>
          <Functions>
            <Function>
              <ImgWrapper>
                <Img theme={{img: img1}} />
              </ImgWrapper>
              <TitleImg data-num={`01`}>ペーパーレス化したい</TitleImg>
            </Function>
            <Function>
              <ImgWrapper>
                <Img theme={{img: img2}} />
              </ImgWrapper>
              <TitleImg data-num={`02`}>外出先でも使いたい</TitleImg>
            </Function>
            <Function>
              <ImgWrapper>
                <Img theme={{img: img3}} />
              </ImgWrapper>
              <TitleImg data-num={`03`}>コストを抑えたい</TitleImg>
            </Function>
          </Functions>
          <Functions>
            <Function>
              <ImgWrapper>
                <Img theme={{img: img4}} />
              </ImgWrapper>
              <TitleImg data-num={`04`}>スマホで使いたい</TitleImg>
            </Function>
            <Function>
              <ImgWrapper>
                <Img theme={{img: img5}} />
              </ImgWrapper>
              <TitleImg data-num={`05`}>リモートワークでも</TitleImg>
            </Function>
            <Function>
              <ImgWrapper>
                <Img theme={{img: img6}} />
              </ImgWrapper>
              <TitleImg data-num={`06`}>システムと連携したい</TitleImg>
            </Function>
          </Functions>
        </FunctionsWrapper>
      </Container>
    </Wrapper>
  )
}
