import React from "react";
import styled from "styled-components";

import { Button } from "../parts/Button";
import { Responsive, Color, FontSize } from "../../utils/Const";

const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: 70vh;
  @media(max-width: ${Responsive.SP}px) {
    flex-direction: column;
  }  
`

const CardImgWrapper = styled.div`
  width: 100%;
  padding: 4rem;
  box-sizing: border-box;
  text-align: center;
  margin: auto;
  @media(max-width: ${Responsive.PC}px) {
    padding: 1rem;
  }
`

const Img = styled.img`
  width: auto;
  height: auto;
  max-width: 400px;
  max-height: 400px;
  @media(max-width: ${Responsive.PC}px) {
    max-width: 100%;
    max-height: 200px;
  }  
`

const CardTextWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
  box-sizing: border-box;
  strong {
    font-weight: normal;
    color: ${Color.Pink};
  }
  @media(max-width: ${Responsive.PC}px) {
    max-width: none;
    padding: 1rem 0;
  }  
`

const ButtonWrapper = styled.div`
  margin: 1rem 0;
  @media(max-width: ${Responsive.PC}px) {
    margin: 0 auto;
  }  
`

const Title = styled.h2`
  position: relative;
  width: 100%;
  font-size: ${FontSize.md};
  font-weight: normal;
  text-align: center;
  &::before {
    content: attr(data-num);
    display: block;
    margin-bottom: 30px;
    color: ${Color.Orange};
    font-size: ${FontSize.md};
    text-align: center;
  }
  &::after {
    content: '';
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 1px;
    background-color: ${Color.Orange};
  }
`

const Description = styled.p`
  width: 100%;
  font-size: ${FontSize.xs};
  font-weight: normal;
  text-align: left;
`

export const Card = ({img, title, num, description, demo=false, signUp=false, children}) => {
  return(
    <CardWrapper>
      <CardImgWrapper>
        <Img src={img} />
      </CardImgWrapper>
      <CardTextWrapper>
        <Title data-num={num} dangerouslySetInnerHTML={{__html: title}} />
        <Description dangerouslySetInnerHTML={{__html: description}} />
        {
          demo &&
          <ButtonWrapper>
            <Button href={`https://demo.otsutome.net/demo/login`}>デモを使ってみる</Button>
          </ButtonWrapper>
        }
        
        {                    
          signUp &&
          <ButtonWrapper>
            <Button href={`https://otsutome.net/account/entry`}>会員登録をする</Button>
          </ButtonWrapper>
        }
      </CardTextWrapper>
    </CardWrapper>
  );
}