import React from "react"
import styled from "styled-components"

import { TitleOrange } from "../components/parts/TitleOrange"
import { YellowWrapper } from "../components/parts/YellowWrapper"
import { Container } from "../components/parts/Container"

import { Responsive, FontSize, Color } from "../utils/Const"

const FlowWrapper = styled.div`
  width: 100%;
  padding: 4rem 0;
  box-sizing: border-box;
  background-color: ${Color.White};
  box-shadow: 15px 15px 0 ${Color.LightOrange};
  @media(max-width: ${Responsive.SP}px) {
    padding: 4rem 1rem;
  }
`

const ArrowWrapper = styled.div`
  display: flex;
  width: 100%;
  @media(max-width: ${Responsive.SP}px) {
    flex-direction: column;
  }
`

const Arrow = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media(max-width: ${Responsive.SP}px) {
    margin: 0.5rem 0;
  }
  &:nth-child(1)::after, &:nth-child(2)::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;
    transform: rotate(45deg) translateY(-50%);
    width: 6px;
    height: 6px;
    border-width: 3px 3px 0 0;
    border-style: solid;
    border-color: ${Color.Orange};
    @media(max-width: ${Responsive.SP}px) {
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto auto 0;
      transform: rotate(135deg) translateY(0%);
      width: 6px;
      height: 6px;
      border-width: 3px 3px 0 0;
      border-style: solid;
    }
  }
`

const Step = styled.span`
  padding: 1rem 1rem;
  box-sizing: border-box;
  background-color: ${Color.Orange};
  font-size: ${FontSize.sm};

  font-weight: bold;
  letter-spacing: 0.20rem;
  color: ${Color.White};
`

const Description = styled.h3`
  width: 100%;
  font-weight: normal;
  text-align: center;
  color: ${Color.Black};
`

export const Flow = () => {
  return (
    <YellowWrapper>
      <Container>
        <TitleOrange>導入の流れ</TitleOrange>
        <FlowWrapper>
          <ArrowWrapper>
            <Arrow>
              <Step>STEP1</Step>
              <Description>メールアドレスを<br />1つだけ用意</Description>
            </Arrow>
            <Arrow>
              <Step>STEP2</Step>
              <Description>会員登録</Description>
            </Arrow>
            <Arrow>
              <Step>STEP3</Step>
              <Description>スタッフを登録<br />※メールアドレス不要</Description>
            </Arrow>
          </ArrowWrapper>
        </FlowWrapper>
      </Container>
    </YellowWrapper>
  )
}
