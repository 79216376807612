import React from "react"
import styled from "styled-components"

import { WhiteWrapper } from "../components/parts/WhiteWrapper"
import { Container } from "../components/parts/Container"
import { TitleOrange } from "../components/parts/TitleOrange"

import { Responsive, FontSize, Color } from "../utils/Const"

import ImgVoice1 from  "../images/voice-1.jpg"
import ImgVoice2 from  "../images/voice-2.jpg"
import ImgVoice3 from  "../images/voice-3.jpg"

const VoicesWrapper = styled.div`
  display: flex;
  width: 100%;
  @media(max-width: ${Responsive.SP}px) {
    flex-direction: column;
  }
`

const VoiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 1.0rem 1.0rem;
  box-sizing: border-box;
`

const VoiceImg = styled.div`
  position: relative;
  width: 240px;
  height: 240px;
  background-image: url(${({theme}) => theme.img});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  box-shadow: 15px 15px 0 ${Color.Shadow};
  @media(min-width: ${Responsive.SP}px) and (max-width: ${Responsive.PC}px) {
    width: 200px;
    height: 200px;
  }  
  @media(max-width: ${Responsive.SP}px) {
    width: 200px;
    height: 200px;
  }
`

const VoiceTitle = styled.h3`
  margin: 1.5rem 0 0.5rem;
  font-weight: normal;
  font-size: ${FontSize.sm};
`

const VoiceDescription = styled.p`
  margin: .5rem 0;
  font-weight: normal;
  font-size: ${FontSize.xs};
`

export const Voices = () => {
  return (
    <WhiteWrapper>
      <Container>
        <TitleOrange>お客様の声</TitleOrange>
        <VoicesWrapper>
          <VoiceWrapper>
            <VoiceImg theme={{ img: ImgVoice1 }} />
            <VoiceTitle>A様（医療業）</VoiceTitle>
            <VoiceDescription>完全無料で、便利なサイトを用意して頂いているので、感謝しております。</VoiceDescription>
          </VoiceWrapper>
          <VoiceWrapper>
            <VoiceImg theme={{ img: ImgVoice2 }} />
            <VoiceTitle>B様（サービス業）</VoiceTitle>
            <VoiceDescription>大変便利な機能で、いつも助かっております。</VoiceDescription>
          </VoiceWrapper>
          <VoiceWrapper>
            <VoiceImg theme={{ img: ImgVoice3 }} />
            <VoiceTitle>C様（IT業）</VoiceTitle>
            <VoiceDescription>完全無料の勤怠管理システムは、非常に少ないので、ありがたい存在です。</VoiceDescription>
          </VoiceWrapper>
        </VoicesWrapper>
      </Container>
    </WhiteWrapper>
  )
}