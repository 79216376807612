export const NewsData = [
  {
    title: `新機能を追加しました。<br /><br />新機能1：勤怠を連続で入力可能になりました。<br />新機能2：スタッフCSV管理機能を追加しました。<br />新機能3：スタッフ検索ができるようになりました。<br />新機能4：デザインの微調整をしました。`,
    date: `2021/05/20`,
  },
  {
    title: `ホームページを刷新しました。`,
    date: `2021/04/20`,
  },
  {
    title: `現在サーバの増強等、鋭意対応中でございます。経過を見ながらの対応になりますので、今後再びご迷惑をお掛けしてしまうかもしれませんが、ご理解・ご協力頂けますと幸いです。`,
    date: `2021/02/02`,
  },
  {
    title: `Web勤怠管理システム「オツトメ！」を事業譲渡しました。`,
    date: `2021/02/01`,
  },
  {
    title: `[お詫び] 先週の1/30(土)〜本日2/1(月)の14時40分頃にかけて、繋がりにくい等不安定な状態や、動作における不具合が発生しておりました。ご迷惑をお掛けし申し訳ございません。`,
    date: `2021/02/01`,
  },
  {
    title: `[お詫び] 昨日まで発生しておりましたアクセス出来ない等の障害ですが、現在は復旧しております。多くのユーザ様にご迷惑をお掛けしましたこと、深くお詫び申し上げます。`,
    date: `2020/07/30`,
  },
  {
    title: `オツトメ！のツイッターを始めました！よろしければフォローしてください！`,
    date: `2011/05/24`,
  },
  {
    title: `【バージョンアップ情報】締め日を会社単位や個人単位で設定できるように致しました！`,
    date: `2011/03/29`,
  },
  {
    title: `CSV出力機能、予定よりも早くリリース！`,
    date: `2010/07/01`,
  },
  {
    title: `CSV出力機能の実装が決定！リリース予定日は７月６日です。`,
    date: `2010/06/25`,
  },
  {
    title: `Web勤怠システム オツトメ！ PRサイトオープン！`,
    date: `2010/04/16`,
  },
  {
    title: `<a href="https://demo.otsutome.net/demo/login" target="_blank">デモ</a>をご用意いたしました！是非一度触ってみてください！`,
    date: `2010/04/15`,
  },
  {
    title: `Web勤怠システム オツトメ！ リリース！`,
    date: `2010/04/12`,
  },
];