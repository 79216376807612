import React from "react";

import { Container } from "../components/parts/Container";
import { WhiteWrapper } from "../components/parts/WhiteWrapper";
import { Card } from "../components/parts/Card";

export const WhiteCard = ({img, title, num, description, demo=false, signUp=false}) => {
  return(
    <WhiteWrapper>
      <Container>
        <Card
          img={img}
          title={title}
          num={num}
          description={description}
          demo={demo}
          signUp={signUp}
        />
      </Container>
    </WhiteWrapper>
  );
}