import React from "react";

import { Container } from "../components/parts/Container";
import { YellowWrapper } from "../components/parts/YellowWrapper";
import { Card } from "../components/parts/Card";

export const YellowCard = ({img, title, num, description, demo=false, signUp=false}) => {
  return(
    <YellowWrapper>
      <Container>
        <Card
          img={img}
          title={title}
          num={num}
          description={description}
          demo={demo}
          signUp={signUp}
        />
      </Container>
    </YellowWrapper>
  );
}