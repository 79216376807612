import React from "react";
import styled from "styled-components";

import { YellowWrapper } from "../components/parts/YellowWrapper";
import { Container } from "../components/parts/Container";
import { Button } from "../components/parts/Button";
import { SpBr } from "../components/parts/SpBr";

import { FontSize, Color } from "../utils/Const";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  margin: auto;
`

const Title = styled.h3`
  width: 100%;
  color: ${Color.Orange};
  font-size: ${FontSize.sm};
  text-align: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin :0 0 1rem;
`

export const Register = () => {
  return(
    <YellowWrapper>
      <Container>
        <ContentWrapper>
          <Title>完全無料で<SpBr />登録は<SpBr />たった数分です。</Title>
          <ButtonWrapper>
            <Button href={`https://otsutome.net/account/entry`}>登録する</Button>
          </ButtonWrapper>
        </ContentWrapper>
      </Container>
    </YellowWrapper>
  )
}