import React from "react";
import styled from "styled-components";

import { Container } from "../components/parts/Container";
import { TitleOrange } from "../components/parts/TitleOrange"
import { Button } from "../components/parts/Button";
import { YellowWrapper } from "../components/parts/YellowWrapper";

import { Color, FontSize } from "../utils/Const";
import { QuestionsData } from "../utils/QuestionsData";

const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 1.0rem 0;
`

const QuestionsArea = styled.dl`
  width: 100%;
  text-align: left;
  margin: 1.0rem 0;
`;

const Question = styled.dt`
  position: relative;

  display: flex;
  align-items: center;
  min-height: 50px;
  margin-left: 65px;
  font-size: ${FontSize.xs};
  border-bottom: 2px solid ${Color.Border};
  box-sizing: border-box;
  &::before {
    content: "Q";

    position: absolute;
    left: -65px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 50px;
    height: 50px;
    margin-right: 15px;

    text-align: center;
    border-radius: 50%;
    color: ${Color.Orange};
    background-color: ${Color.White};
    border: 2px solid ${Color.Orange};
    font-size: ${FontSize.sm};
    font-weight: bold;
  }
`

const Answer = styled.dd`
  position: relative;

  display: flex;
  align-items: center;
  min-height: 50px;
  margin-left: 65px;
  margin-top: 1.5rem;
  margin-bottom: 3rem;

  font-size: ${FontSize.xs};
  border-bottom: 2px solid ${Color.Border};
  box-sizing: border-box;
  a {
    display: contents;
  }
  &::before {
    content: "A";

    position: absolute;
    left: -65px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 50px;
    height: 50px;
    margin-right: 15px;

    text-align: center;
    border-radius: 50%;
    color: ${Color.White};
    background-color: ${Color.Orange};
    font-size: ${FontSize.sm};
    font-weight: bold;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const TopQuestions = () => {
  return(
    <YellowWrapper>
      <Container>
        <TitleOrange>よくあるご質問</TitleOrange>
        <QuestionsWrapper>
          <QuestionsArea>
            {QuestionsData.filter((n, i) => n.top).map((n) => {
              return (
                <>
                  <Question>{n.question}</Question>
                  <Answer dangerouslySetInnerHTML={{__html: n.answer}} />
                </>
              )
            })}
          </QuestionsArea>
        </QuestionsWrapper>
        <ButtonWrapper>
          <Button to={`/questions`}>よくあるご質問をもっと見る</Button>
        </ButtonWrapper>
      </Container>
    </YellowWrapper>
  );
}