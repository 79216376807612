import React from "react";
import styled from "styled-components";

import ImgBanner01EC from "./../images/banner_01ec.png"
import ImgBannerNEP from "./../images/banner_nep.jpg"
import { Responsive } from "../utils/Const";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  margin: 4rem auto;
  width: 100%;
  max-width: ${Responsive.PC}px;
  box-sizing: border-box;
  @media(max-width: ${Responsive.SP}px) {
    flex-direction: column;
    padding: 0 1rem;
  }
`

const Link = styled.a`
  display: block;
  transition: 0.5s;
  &:hover {
    opacity: .6;
  }
`

const Img = styled.img`
  width: auto;
  max-height: 320px;
`

export const Banner = () => {

  const sendGTag = (eventName, category, label) => {
    if (process.env.NODE_ENV === "production" && window.gtag) {
      window.gtag('event', eventName, {'event_category': category,'event_label': label});
    }
  }

  return(
    <Wrapper>
      <Link href={"https://nextribe.co.jp/lp/01engineer-camp/"} target={`_blank`} rel={`noopener noreferrer`} onClick={() => sendGTag("0→1 ENGINEER CAMP バナークリック", "バナークリック", "0→1 ENGINEER CAMP")} >
        <Img src={ImgBanner01EC} alt="0→1 ENGINEER CAMP（ゼロイチエンジニアキャンプ）は実務レベルのプログラマ研修で、即戦力レベルのエンジニアを育てる超実践型エンジニア育成サービスです。" />
      </Link>
      <Link href={'https://nextribe.co.jp/lp/next-engineer-project/'} target={`_blank`} rel={`noopener noreferrer`} onClick={() => sendGTag('NEXT ENGINEER PROJECT バナークリック', 'バナークリック', 'NEXT ENGINEER PROJECT')} >
        <Img src={ImgBannerNEP} alt="NEXT ENGINEER PROJECT（ネクストエンジニアプロジェクト）は、完全無料＆内定保証あり！必ずエンジニアになれるプログラミングスクールです。" />
      </Link>
    </Wrapper>
  )
}
