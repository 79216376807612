import React from "react"
import styled from "styled-components"

import { WhiteWrapper } from "../components/parts/WhiteWrapper";
import { Container } from "../components/parts/Container"

import { Responsive, FontSize, Color } from "../utils/Const"

const ContentWrapper = styled.div`
`

const Title = styled.h2`
  color: ${Color.Orange};
  font-size: ${FontSize.md};
  text-align: center;
`

const PlanCardsWrapper = styled.div`
  display: flex;
  align-items: center;
  @media(max-width: ${Responsive.PC}px) {
    flex-direction: column;
  }
`

const PlanCard = styled.div`
  width: 33%;
  margin: 0 1rem;
  padding: 0 1rem;
  box-sizing: border-box;
  background-color: ${({isNo1}) => isNo1 ? Color.Orange : Color.White};
  border-radius: 0.5rem;
  border: 1px solid ${Color.Orange};
  @media(max-width: ${Responsive.PC}px) {
    width: 100%;
    max-width: 400px;
    margin: 1rem 1rem;
    order: ${({isNo1}) => isNo1 ? '-1' : 'inherit'};
  }
`

const PlanTitle = styled.h3`
  text-align: center;
  color: ${({isNo1}) => isNo1 ? Color.White : Color.Orange};
`

const PlanLists = styled.ul`
  color: ${({isNo1}) => isNo1 ? Color.White : Color.Orange};
  font-weight: bold;
  list-style: none;
  margin: 0;
  padding: 0;
`

const PlanList = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  text-align: center;
  font-size: ${FontSize.sm};
  svg {
    margin: 0.75rem 0;
    @media(max-width: ${Responsive.PC}px) {
      margin: 0.5rem 0;
    }
  }
  &:not(:last-child) {
    &:after {
      position: absolute;
      content: '';
      width: 100%;
      max-width: 200px;
      height: 1px;
      left: 0;
      right: 0;
      bottom: -1.0rem;
      margin: auto;
      background-color: ${({isNo1}) => isNo1 ? Color.White : Color.Orange};
    }
  }
`

const Plan = ({name, cost, people, func, isNo1=false}) => {
  return (
    <PlanCard isNo1={isNo1}>
      <PlanTitle isNo1={isNo1}>{name}</PlanTitle>
      <PlanLists isNo1={isNo1}>
        <PlanList isNo1={isNo1}>
          <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M7.5 15V7.5m0 0l-5-7m5 7l5-7M3 7.5h9m-9 4h9" stroke="currentColor"></path></svg>
          {cost}
        </PlanList>
        <PlanList isNo1={isNo1}>
          <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M5.5 0a3.499 3.499 0 100 6.996A3.499 3.499 0 105.5 0zm-2 8.994a3.5 3.5 0 00-3.5 3.5v2.497h11v-2.497a3.5 3.5 0 00-3.5-3.5h-4zm9 1.006H12v5h3v-2.5a2.5 2.5 0 00-2.5-2.5z" fill="currentColor"></path><path d="M11.5 4a2.5 2.5 0 100 5 2.5 2.5 0 000-5z" fill="currentColor"></path></svg>
          人数{people}
        </PlanList>
        <PlanList isNo1={isNo1}>
          <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M10.854.146a.5.5 0 00-.708 0L0 10.293V14.5a.5.5 0 00.5.5h4.207L14.854 4.854a.5.5 0 000-.708l-4-4z" fill="currentColor"></path></svg>
          機能{func}
        </PlanList>
      </PlanLists>
    </PlanCard>
  )
}

export const Plans = () => {
  return (
    <WhiteWrapper>
      <Container>
        <ContentWrapper>
          <Title>料金プラン</Title>
          <PlanCardsWrapper>
            <Plan name={`A社`} cost={`一部無料`} people={`制限あり`} func={`制限あり`} isNo1={false} />
            <Plan name={`オツトメ！`} cost={`完全無料`} people={`制限なし`} func={`制限なし`} isNo1={true} />
            <Plan name={`B社`} cost={`有料`} people={`制限なし`} func={`制限なし`} isNo1={false} />
          </PlanCardsWrapper>
        </ContentWrapper>
      </Container>
    </WhiteWrapper>
  )
}
