import React from "react";
import styled from "styled-components";

import imgCsv from  "../images/csv.png";
import imgMessage from "../images/message.png";
import imgStaff from "../images/staff.png";

import { Container } from "../components/parts/Container";
import { Button } from "../components/parts/Button";
import { Responsive, Text, Color, FontSize } from "../utils/Const";

const ColorMixWrapper = styled.div`
  width: 100%;
  padding: 1rem 0;
  box-sizing: border-box;
  background-color: ${Color.White};
  position: relative;
  z-index: 0;
  &::before {
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 25vh;
    background-color: ${Color.Yellow};
    bottom: 0;
    padding: 0;
    box-sizing: border-box;
    z-index: -1;
  }
`;

const Title = styled.h2`
  width: 100%;
  color: ${Color.Orange};
  font-size: ${FontSize.md};
  text-align: center;
`

const FunctionsWrapper = styled.div`
  p {
    font-size: ${FontSize.xs};
    margin: 0.5rem 0;
  }
`

const Functions = styled.div`
  display: flex;
  @media(max-width: ${Responsive.SP}px) {
    flex-direction: column;
  }
`

const Function = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 4rem 2rem;
  padding: 0;
  @media(max-width: ${Responsive.SP}px) {
    margin: 2rem 0rem;
  }
`

const Img = styled.img`
  width: 100%;
`

const FunctionTitle = styled.h3`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  background-color: ${Color.White};
  text-align: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  margin: 1rem 0;
`

export const FunctionCard = () => {
  return(
    <ColorMixWrapper>
      <Container>
        <FunctionsWrapper>
          <Title>さまざまな機能</Title>
          <p>{Text.SERVICE_NAME}は完全無料ながらも、勤怠管理に必要な機能をそろえております。</p>
          <p>勤怠管理以外にも、外部システム連携や従業員とのメッセージなどもご利用できます。</p>
          <ButtonWrapper>
            <Button to={`/functions`}>機能をもっと見る</Button>
          </ButtonWrapper>
          <Functions>
            <Function>
              <Img src={imgCsv} />
              <FunctionTitle>CSV連携</FunctionTitle>
            </Function>
            <Function>
              <Img src={imgMessage} />
              <FunctionTitle>メッセージ</FunctionTitle>
            </Function>
            <Function>
              <Img src={imgStaff} />
              <FunctionTitle>スタッフ管理</FunctionTitle>
            </Function>
          </Functions>
        </FunctionsWrapper>
      </Container>
    </ColorMixWrapper>
  )
}
