import React from "react";
import styled from "styled-components";

import { Container } from "../components/parts/Container";
import { TitleView } from "../components/parts/TitleView";
import { Button } from "../components/parts/Button";
import { WhiteWrapper } from "../components/parts/WhiteWrapper";

import { Responsive, Color, FontSize } from "../utils/Const";
import { NewsData } from "../utils/NewsData";

import imgTwitter from "../images/twitter.svg";

const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    width: 100%;
    color: ${Color.Black};
  }
`

const Title = styled.h2`
  color: ${Color.Orange};
  font-size: ${FontSize.md};
  text-align: center;
`

const NewsArea = styled.div`
  width: 100%;
`;

const New = styled.div`
  width: 100%;
  border-top: 1px solid ${Color.Border};
  margin: 1rem 0;
  padding: 1.0rem 2.0rem;
  box-sizing: border-box;
  &:last-child {
    border-bottom: 1px solid ${Color.Border};
  }
  @media(max-width: ${Responsive.SP}px) {
    padding: 1rem 0rem;
  }  
`

const NewsTitle = styled.p`
  font-size: ${FontSize.xs};
  margin: 1rem 0;
`

const NewsTime = styled.time`
  display: block;
  text-align: left;
  margin: 1rem 0 0;
  color: ${Color.LightGray};
`

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
  display: flex;
  justify-content: center;
`

const SNSWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 1.0rem 0;
  img {
    width: 32px;
  }
`;

export const News = ({ isLimit=false }) => {
  const LIMIT = 1;
  return(
    <WhiteWrapper>
      <Container>
        {
          isLimit ? <Title>お知らせ</Title> : <TitleView>お知らせ</TitleView>
        }
        { !isLimit &&
          <SNSWrapper>
            <a href="https://twitter.com/otsutome" target="_blank" rel="noopener noreferrer"><img alt={"Twitter"} src={imgTwitter} /></a>
          </SNSWrapper>
        }
        <NewsWrapper>
          <NewsArea>
            {
              NewsData.slice(0, isLimit ? LIMIT : NewsData.length).map((n) => {
                return(
                  <New>
                    <NewsTime>{n.date}</NewsTime>
                    <NewsTitle dangerouslySetInnerHTML={{__html: n.title}} />
                  </New>
                )
              })
            }
          </NewsArea>
          { isLimit &&
            <ButtonWrapper>
              <Button to={`/news`}>お知らせをもっと見る</Button>
            </ButtonWrapper>
          }
        </NewsWrapper>
      </Container>
    </WhiteWrapper>
  );
}