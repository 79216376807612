import React from "react";

import img1 from "../images/pc.png";
import img2 from "../images/sp.png";
import img3 from "../images/free.png";

import { Header } from "../components/parts/Header";
import { FirstView } from "../components/FirstView";
import { Banner } from "../components/Banner";
import { Medals } from "../components/Medals";
import { News } from "../components/News";
import { YellowCard } from "../components/YellowCard";
import { WhiteCard } from "../components/WhiteCard";
import { FunctionCard } from "../components/FunctionCard";
import { Recommend } from "../components/Recommend";
import { Free } from "../components/Free";
import { Register } from "../components/Register";
import { Flow } from "../components/Flow";
import { Plans } from "../components/Plans";
import { Voices } from "../components/Voices";
import { TopQuestions } from "../components/TopQuestions";
import { Footer } from "../components/Footer";
import { ChosenReason } from "../components/ChosenReason";

import { Layout } from "../components/parts/Layout";

import { Text } from "../utils/Const";

const IndexPage = () => {
  return (
    <Layout>
      <Header />
      <FirstView />
      <Banner />
      <Medals />
      <ChosenReason />
      <Recommend />
      <YellowCard
        img={img1}
        title={`<strong>シンプル</strong>な<br />インターフェース`}
        num={`01`}
        description={`勤怠管理に必要な機能を用意。<br />難しい操作は一切不要です。`}
        demo={true}
      />
      <WhiteCard
        img={img2}
        title={`パソコンや<strong>スマホ</strong><br />に対応`}
        num={`02`}
        description={`URLにアクセスするだけでスマホ・タブレット専用のページへアクセス可能です。`}
        demo={true}
      />
      <YellowCard
        img={img3}
        title={`<strong>完全無料</strong><br />で使えます`}
        num={`03`}
        description={`${Text.SERVICE_NAME}は完全無料です。<br />勤怠管理システムの導入コストでお悩みの場合は、まずは${Text.SERVICE_NAME}をお試しください。`}
        demo={true}
        signUp={false}
      />
      <FunctionCard />
      <Free />
      <Plans />
      <Flow />
      <Register />
      <Voices />
      <TopQuestions />
      <News isLimit={true} />
      <Footer />
    </Layout>
  )
}

export default IndexPage
