import React from "react";
import styled from "styled-components";

import { Container } from "../components/parts/Container";
import { Button } from "../components/parts/Button";
import { SpBr } from "../components/parts/SpBr";

import { Responsive, Color, FontSize } from "../utils/Const";

import ImgFirstView from "../images/first-view.jpg"

const FirstViewWrapper = styled.div`
position: relative;
  width: 100%;
  height: 90vh;
  min-height: 600px;
  background-image: url(${ImgFirstView});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @media(max-width: ${Responsive.PC}px) {
    min-height: auto;
    height: 60vh;
  }
`;

const FirstViewContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90vh;
  background-color: rgba(0,0,0,.1);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
  @media(max-width: ${Responsive.PC}px) {
    height: 60vh;
  }
`

const ButtonWrapper = styled.div`
  margin: 1.5rem 0;
`

const Title = styled.h1`
  width: 100%;
  margin: 1rem 0;
  font-size: ${FontSize.xl};
  font-weight: bold;
  color: ${Color.White};
  text-shadow: 1px 2px 9px #333;
  box-sizing: border-box;
  @media(max-width: ${Responsive.SP}px) {
    font-size: ${FontSize.md};
  }
`

const Description = styled.span`
  width: 100%;
  font-size: ${FontSize.xs};
  font-weight: bold;
  color: ${Color.White};
  text-shadow: 1px 2px 9px #333;
  @media(max-width: ${Responsive.SP}px) {
    font-size: ${FontSize.xxs};
  }
`

export const FirstView = () => {
  return (
    <FirstViewWrapper id={`first-view`}>
      <FirstViewContent>
        <Container>
          <Title>
            完全無料の<br />Web勤怠管理システム
          </Title>
          <Description>パソコン・スマホ・タブレット<SpBr />からご利用可能</Description>
          <ButtonWrapper>
            <Button href={`https://demo.otsutome.net/demo/login`}>デモを使ってみる</Button>
          </ButtonWrapper>
        </Container>
      </FirstViewContent>
    </FirstViewWrapper>
  )
}