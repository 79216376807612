import React from "react";
import styled from "styled-components";

import { Container } from "../components/parts/Container";
import { WhiteWrapper } from "../components/parts/WhiteWrapper";
import { LightYellowWrapper } from "../components/parts/LightYellowWrapper";
import { SpBr } from "../components/parts/SpBr";

import { Responsive, Text, Color, FontSize } from "../utils/Const";

const MedalsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media(max-width: ${Responsive.SP}px) {
    flex-direction: column;
  }  
`

const Medal = styled.h3`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  margin: 4rem 0;
  font-size: ${FontSize.lg};
  font-weight: normal;
  color: ${Color.Block};
  text-align: center;

  @media(max-width: ${Responsive.SP}px) {
    width: 100%;
    margin: 2rem 0;
  }  

  strong {
    display: contents;
    font-size: ${FontSize.xl};
    font-weight: bold;
  }

  &::before,
  &::after {
    position: absolute;
    content: '';
    width: 2px;
    height: 100px;
    background-color: ${Color.Orange};
  }
  
  &::before {
    left: 0;
    margin-right: 30px;
    transform: rotate(-20deg);
    @media(max-width: ${Responsive.SP}px) {
      left: 20px;
    }  
  }
  &::after {
    right: 0;
    margin-left: 30px;
    transform: rotate(20deg);
    @media(max-width: ${Responsive.SP}px) {
      right: 20px;
    }  
  }
`

const CatchCopyWrapper = styled.div`
  position: relative;
  padding: 2rem 0;
	text-align: center;
  &::after {
    content: '';
    position: absolute;
    bottom: -2rem;
    left: 50%;
    transform: translate(-50%) rotate(90deg);
    width: 1px;
    height: 25%;
    background-color: ${Color.LightYellow};
  }
`

const CatchCopyTitle = styled.h2`
  color: ${Color.Orange};
  font-size: ${FontSize.md};
  text-align: center;
`

const CatchCopyDescription = styled.p`
  color: ${Color.Black};
  font-size: ${FontSize.sm};
  text-align: center;
  strong {
    text-emphasis: circle ${Color.Orange};
    -webkit-text-emphasis: circle ${Color.Orange};
  }
`

export const Medals = () => {
  return (
    <>
    <WhiteWrapper>
      <Container>
        <CatchCopyTitle>たくさんの方々に<SpBr />使われています</CatchCopyTitle>
        <MedalsWrapper>
          <Medal>導入実績<br /><strong>2,000社</strong></Medal>
          <Medal>利用されて<br /><strong>10年</strong></Medal>
        </MedalsWrapper>
      </Container>
    </WhiteWrapper>
    <LightYellowWrapper>
      <Container>
        <CatchCopyWrapper>
          <CatchCopyTitle>完全無料で、<SpBr />使われている、<SpBr />勤怠管理システム</CatchCopyTitle>
          <CatchCopyDescription>{Text.SERVICE_NAME}は、<SpBr />勤怠管理業務に必要な機能を、<SpBr /><strong>完全無料</strong><SpBr />で備えております。</CatchCopyDescription>
          <CatchCopyDescription>操作性もシンプルかつ、<SpBr /><strong>メールアドレス1つだけ</strong><SpBr />ですぐにご利用できます。</CatchCopyDescription>
        </CatchCopyWrapper>
      </Container>
    </LightYellowWrapper>
    </>
  )
}