import React from "react"
import styled from "styled-components"

import { FontSize, Color } from "../../utils/Const"

const Title = styled.h2`
  color: ${Color.Orange};
  font-size: ${FontSize.md};
  text-align: center;
`

export const TitleOrange = ({children}) => {
  return (
    <Title>{children}</Title>
  )
}
