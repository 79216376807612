export const QuestionsData = [
  {
    category: `費用`,
    question: `導入費用、ランニングコストはいくらですか？`,
    answer: `完全無料のサービスとなっております。費用は発生しません。`,
  },
  {
    category: `費用`,
    question: `完全無料の理由を教えてください。`,
    answer: `当サービスは、広告によって運営しております。`,
  },
  {
    category: `費用`,
    question: `機器やサーバーなどを用意する必要はありますか？`,
    answer: `機器、サーバーはご用意頂く必要はございません。<br />当社管理のサーバー内にてサービスを提供しております。`,
  },
  {
    top: true,
    category: `機能`,
    question: `使い方を教えてください。`,
    answer: `チュートリアルをご用意しております。<a href="https://otsutome.net/tutorial" target="_blank" rel="noopener noreferrer">チュートリアルはこちら</a>`,
  },
  {
    top: true,
    category: `機能`,
    question: `管理者は勤怠データを修正できますか？`,
    answer: `管理者は勤怠データを修正できません。スタッフご本人のみが修正可能です。`,
  },
  {
    top: true,
    category: `機能`,
    question: `1日に複数回の休憩時間を登録できますか？`,
    answer: `「時間の設定」で複数回登録することができます。`,
  },
  {
    category: `機能`,
    question: `「時間の設定」で設定した休憩時間以外の休憩時間を登録できますか？`,
    answer: `申し訳ございませんが、現状は「時間の設定」以外の任意の休憩時間の登録はできません。`,
  },
  {
    category: `機能`,
    question: `「時間の設定」の変更後、過去の勤怠データはどうすれば再計算されますか？`,
    answer: `過去の勤怠は確定したデータとなるため、自動では休憩時間や残業時間の再計算は行われません。<br />---<br />「月間勤怠一覧」> 変更したい日付の「変更」 >「確認」>「送信」<br />---<br />内容は変更せず編集することで、休憩時間や残業時間などの再計算が行われます。`,
  },
  {
    category: `機能`,
    question: `位置情報（GPS）やIPアドレスの確認はできますか？`,
    answer: `申し訳ございませんが、現状は上記の情報は確認できません。`,
  },
  {
    category: `機能`,
    question: `ガラケーは対応しておりますか？`,
    answer: `申し訳ございませんが、ガラケーのサポートは終了しました。`,
  },
  {
    category: `機能`,
    question: `退会はできますか？`,
    answer: `退会は管理画面より可能です。<br />「メニュー > 退会手続き」より退会が可能です。<br />一度退会しますと、データの復元ができないため、ご注意ください。`,
  },
  {
    category: `その他`,
    question: `導入サポートはありますか？`,
    answer: `当サービスは、完全無料のため、直接的なサポートはしておりません。基本的には、チュートリアルをご参考いただき、ご利用していただくことになっております。`,
  },
  {
    category: `その他`,
    question: `サービスの資料はパンフレットはありますか？`,
    answer: `現状はWebサイト以外の資料（パンフレットやPDFなど）はございません。`,
  },
  /* コピー用
  {
    question: ``,
    answer: ``,
  },
  */
];