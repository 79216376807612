import React from "react";
import styled from "styled-components";

import { Responsive } from "../../utils/Const"

const Br = styled.br`
  display: none;
  @media(max-width: ${Responsive.SP}px) {
    display: block;
  }
`

export const SpBr = () => {
  return (
    <Br />
  )
}