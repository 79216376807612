import React from "react";
import styled from "styled-components";

import { Container } from "../components/parts/Container";
import { WhiteWrapper } from "../components/parts/WhiteWrapper";
import { Responsive, Color, FontSize } from "../utils/Const";
import { SpBr } from "../components/parts/SpBr";

import img1 from  "../images/chosen-free.png";
import img2 from  "../images/chosen-setting.png";
import img3 from  "../images/chosen-symple.png";
import img4 from  "../images/chosen-coordination.png";

const CatchCopyTitle = styled.h2`
  color: ${Color.Orange};
  font-size: ${FontSize.md};
  text-align: center;
`

const BoxWrapp = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  @media(max-width: ${Responsive.SP}px) {
    flex-direction: column;
    align-items: center;
  }
`

const Box = styled.div`
  width: calc(50% - 4em);
  margin: 2em 0;
  box-sizing: border-box;
  background-color: ${Color.Yellow};
  @media(max-width: ${Responsive.SP}px) {
    width: calc(100%);
  }
`
const BoxTitle = styled.h3`
  margin: auto;
  background-color: ${Color.Orange};
  padding: 4px;
  text-align: center;
  color: #FFF;
  font-weight: bold;
  letter-spacing: 0.05em;
  font-size: ${FontSize.sm};
`
const BoxText = styled.div`
  padding: 15px 20px;
  margin: 0;
  strong {
    background: linear-gradient(${Color.LightYellow} 90%, ${Color.Orange} 0%);
  }
`

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
`

const Img = styled.div`
  width: 100px;
  height: 100px;
  background-image: url(${({theme}) => theme.img});
  background-size: cover;
`

const Description = styled.p`
`

const Reason = ({title, img, descriptions}) => {
  return (
    <Box>
      <BoxTitle>{title}</BoxTitle>
      <BoxText>
        <ImgWrapper>
          <Img theme={{img: img}} />
        </ImgWrapper>
        {
          descriptions.map(description => <Description dangerouslySetInnerHTML={{__html: description}} />)
        }
      </BoxText>
    </Box>
  )
}

export const ChosenReason = () => {
  return(
    <WhiteWrapper>
      <Container>
        <CatchCopyTitle>オツトメ！が<SpBr />選ばれる理由</CatchCopyTitle>
        <BoxWrapp>
          <Reason
            title={`人数制限無しで完全無料！`}
            img={img1}
            descriptions={[
              `オツトメ！は<strong>完全無料で人数制限</strong>もありません。`,
              `今まで勤怠管理システムに費やしていた<strong>コストを削減</strong>できます。`,
            ]}
          />
          <Reason
            title={`導入楽々`}
            img={img2}
            descriptions={[
              `<strong>メールアドレス一つだけ</strong>で簡単登録！`,
              `難しい設定はなく、登録すればすぐに勤怠管理システムを使用できます。`,
            ]}
          />
          <Reason
            title={`シンプルで最低限の機能`}
            img={img3}
            descriptions={[
              `<strong>シンプルなインターフェース</strong>で使いやすい設計になっています。`,
              `<strong>必要な機能</strong>は備わっており、<strong>無駄がありません</strong>。`,
              `有料プランだけど使わない機能がたくさんあると感じれば、一度オツトメ！を体験してみてください。`,
            ]}
          />
          <Reason
            title={`外部システム連携`}
            img={img4}
            descriptions={[
              `オツトメ！は<strong>外部システムとの連携も可能</strong>です。`,
              `勤怠一覧などの様々なデータをCSV出力し、お好きな給与計算ソフトウェアに取り込むことができます。`,
              `決まったソフトウェアしか連携できないなどのお悩みも解決します。`,
            ]}
          />
        </BoxWrapp>
      </Container>
    </WhiteWrapper>
  );
}